

import axios from 'axios';

// const SHIPWAY_API_URL = 'https://shipway.in/api/getOrderShipmentDetails';
//https://pickupkart.in
//https://pickup-server-y10z.onrender.com


const SHIPWAY_API_URL = 'https://pickupkart.in/api/getOrderShipmentDetails';

const USERNAME = 'omkar8856@gmail.com';
const PASSWORD = '7617429f144d323fae769e9271879a42';

export const getOrderShipmentDetails = async (orderId) => {
  try {
    const response = await axios.post(SHIPWAY_API_URL, {
      username: USERNAME,
      password: PASSWORD,
      order_id: orderId,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shipment details:', error);
    throw error;
  }
};
