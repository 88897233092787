import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import air from '../Images/airdelivery.jpg';
import pickupExpress from '../Images/express1.jpg';
import deliveryData from '../../updatepin/deliveryPin.json';
import pickupData from '../../updatepin/pickupPin.json';

const NewParcel = () => {
    const [weight, setWeight] = useState("");
    const [deliveryPincode, setDeliveryPincode] = useState('');
    const [pickupPincode, setPickupPincode] = useState('');
    const [expressRate, setExpressRate] = useState(null);
    const [airRate, setAirRate] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [cardsVisible, setCardsVisible] = useState(false);
    const [destination, setDestination] = useState({ city: '', state: '' });
    const [pickupDestination, setPickupDestination] = useState({ city: '', state: '' });
    const [expressExpectedDeliveryTime, setExpressExpectedDeliveryTime] = useState('');
    const [airExpectedDeliveryTime, setAirExpectedDeliveryTime] = useState('');

    const [length, setLength] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [volumeRate, setVolumeRate] = useState(0);
    const [volumeRateAir, setVolumeRateAir] = useState(0);
    const [parcelVolume, setParcelVolume] = useState(0);

    const navigate = useNavigate();

    const handleCardClose = () => {
        setCardsVisible(false);
        setShowSpinner(false);
    };

    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    };

    const handlePickupPincodeChange = (event) => {
        setPickupPincode(event.target.value);
        const selectedPickup = pickupData.find((pickup) => pickup.pin === event.target.value);
        if (selectedPickup) {
            setPickupDestination({ city: selectedPickup.city, state: selectedPickup.state });
        } else {
            setPickupDestination({ city: '', state: '' });
        }
    };

    const handleDeliveryPincodeChange = (event) => {
        setDeliveryPincode(event.target.value);
        const selectedDelivery = deliveryData.find((delivery) => delivery.pin === event.target.value);
        if (selectedDelivery) {
            setDestination({ city: selectedDelivery.city, state: selectedDelivery.state });
        } else {
            setDestination({ city: '', state: '' });
        }
    };

    const getExpectedDeliveryTime = (destinationType) => {
        const deliveryTimes = {
            p1: { express: "1 - 2 days" },
            p2: { express: "1 - 2 days" },
            p3: { express: "1 - 2 days" },
            p4: { express: "1 - 2 days" },
            p5: { express: "1 - 2 days", air: "4-5 days" },
            p6: { express: "1 - 2 days", air: "4-5 days" },
            p7: { express: "1 - 2 days", air: "4-5 days" },
        };
        return deliveryTimes[destinationType] || { express: 'N/A', air: 'N/A' };
    };

    const calculateRate = () => {
        if (!weight || !deliveryPincode || !pickupPincode) {
            alert('Please fill in all fields');
            return;
        }

        setShowSpinner(true);

        setTimeout(() => {
            setShowSpinner(false);
        }, 1000);

        const selectedDelivery = deliveryData.find((delivery) => delivery.pin === deliveryPincode);
        const selectedPickup = pickupData.find((pickup) => pickup.pin === pickupPincode);

        if (!selectedDelivery) {
            alert('Invalid delivery address');
            return;
        }

        if (!selectedPickup) {
            alert('Invalid pickup address');
            return;
        }

        const deliveryTimes = getExpectedDeliveryTime(selectedDelivery.type);
        setExpressExpectedDeliveryTime(deliveryTimes.express);
        setAirExpectedDeliveryTime(deliveryTimes.air);

        let expressRate = 0;
        let airRate = 0;

        switch (selectedDelivery.type) {
            case 'p1':
            case 'p2':
            case 'p3':
            case 'p4':
                expressRate = calculateRateForP1ToP4();
                break;
            case 'p5':
                expressRate = calculateRateForP5P7('express');
                airRate = calculateRateForP5P7('air');
                break;
            case 'p6':
                expressRate = calculateRateForP5P7('express');
                airRate = calculateRateForP5P7('air');
                break;
            case 'p7':
                expressRate = calculateRateForP5P7('express');
                airRate = calculateRateForP5P7('air');
                break;
            default:
                alert('Unsupported destination type');
                return;
        }

        setExpressRate(expressRate);
        setAirRate(airRate);
        setCardsVisible(true);
    };

    const calculateRateForP1ToP4 = () => {
        const ratePerKg = weight <= 10 ? 40 : 35;
        return weight <= 1 ? 80 : weight * ratePerKg + (weight > 10 ? 90 : 40);
    };

    const calculateRateForP5P7 = (pickupType) => {
        const rates = {
            express: [0, 120, 140, 150],
            air: [120, 170, 180, 200],
        };
        const weightLimits = [250, 1000];
        const rateIndex = ['p5', 'p6', 'p7'].indexOf(destination.type) + 1;

        if (weight <= weightLimits[0]) return rates[pickupType][0];
        if (weight <= weightLimits[1]) return rates[pickupType][rateIndex];
        alert("Document accept only up to 1000 grams, for more weight go for parcel");
        return 0;
    };

    const handleProceed = (rate, type) => {
        const pickupDetails = { pickupPincode, pickupDestination };
        const deliveryDetails = { deliveryPincode, destination };
        const packageDetails = { weight, length, width, height };
        const expectedDeliveryTime = type === 'express' ? expressExpectedDeliveryTime : airExpectedDeliveryTime;

        navigate("/DocumentForm", { state: { pickupDetails, deliveryDetails, packageDetails, rate, expectedDeliveryTime } });
    };

    return (
        <div className="bg-white p-10 rounded-xl">
            <div className='lg:grid lg:grid-cols-2 gap-4'>
                <div>
                    <label className="block text-gray-500 text-sm font-bold mb-2">Pickup Pincode*</label>
                    <input className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline" type="text" value={pickupPincode} onChange={handlePickupPincodeChange} />
                    <p className="text-green-500 text-[13px] font-medium lowercase">{pickupDestination.city}</p>
                </div>
                <div>
                    <label className="block text-gray-500 text-sm font-bold mb-2">Delivery Pincode*</label>
                    <input className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline" type="text" value={deliveryPincode} onChange={handleDeliveryPincodeChange} />
                    <p className="text-green-500 text-[13px] font-medium lowercase">{destination.city}</p>
                </div>
            </div>
            <div className="grid lg:grid-cols-4 mt-6 gap-2">
                <div className="mb-4">
                    <label className="block text-gray-500 text-sm font-bold mb-2">Parcel Weight (in kg) *</label>
                    <input
                        type="number"
                        step="0.01"
                        className="shadow appearance-none border-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-500 focus:shadow-outline"
                        value={weight}
                        onChange={handleWeightChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-500 text-sm font-bold mb-2">Length (in cm) *</label>
                    <input
                        type="number"
                        step="0.01"
                        className="shadow appearance-none border-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-500 focus:shadow-outline"
                        value={length}
                        onChange={(e) => setLength(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-500 text-sm font-bold mb-2">Width (in cm) *</label>
                    <input
                        type="number"
                        step="0.01"
                        className="shadow appearance-none border-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-500 focus:shadow-outline"
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-500 text-sm font-bold mb-2">Height (in cm) *</label>
                    <input
                        type="number"
                        step="0.01"
                        className="shadow appearance-none border-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-500 focus:shadow-outline"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex justify-center mt-4">
                <button
                    className="bg-[#303379] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={calculateRate}
                >
                    Calculate Rate
                </button>
            </div>
            {showSpinner && (
                <div className="flex justify-center mt-4">
                    <div className="loader"></div>
                </div>
            )}
            {cardsVisible && (
                <div className="mt-4">
                    <div className="flex justify-end">
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleCardClose}
                        >
                            Close
                        </button>
                    </div>
                    {expressRate !== null && (
                        <div className="p-4 bg-gray-100 rounded-lg shadow-md mb-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <img src={pickupExpress} alt="Express Delivery" className="h-16 w-16 rounded-full" />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-xl font-bold text-gray-900">Express Delivery</h3>
                                    <p className="mt-2 text-gray-600">Estimated Delivery Time: {expressExpectedDeliveryTime}</p>
                                    <p className="mt-2 text-gray-600">Rate: ₹{expressRate}</p>
                                    <button
                                        className="mt-4 bg-[#303379] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                        onClick={() => handleProceed(expressRate, 'express')}
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {airRate !== null && (
                        <div className="p-4 bg-gray-100 rounded-lg shadow-md">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <img src={air} alt="Air Delivery" className="h-16 w-16 rounded-full" />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-xl font-bold text-gray-900">Air Delivery</h3>
                                    <p className="mt-2 text-gray-600">Estimated Delivery Time: {airExpectedDeliveryTime}</p>
                                    <p className="mt-2 text-gray-600">Rate: ₹{airRate}</p>
                                    <button
                                        className="mt-4 bg-[#303379] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                        onClick={() => handleProceed(airRate, 'air')}
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default NewParcel;
