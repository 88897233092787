import React, { useState, useRef, useEffect } from "react";
import "./BookNow.css";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const BookNow = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [toastClosed, setToastClosed] = useState(false);
  const location = useLocation();
  const [formData, setFormData] = useState({
    pname: "",
    pnumber: "",
    paddress: "",
    ppin: "",
    dpin: "",
    packageType: "",
    orderDate: new Date().toISOString().slice(0, 10),
  });
  const Navigate = useNavigate()

  const [showSpinner, setShowSpinner] = useState(false);

  const options = ["Document", "Parcel", "Cargo"];
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFormData({
      ...formData,
      packageType: option,
    });
    setIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const URL1 = "https://pickup-server-y10z.onrender.com"
  const URL2 = "http://localhost:7000"
  const URL3 = "https://pickupkart.in"

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setShowSpinner(true);

      const response = await axios.post(`${URL3}/quickOrder/new_QuickOrder`, formData);

      if (response.status === 200) {
        toast.success("Order created successfully we will contact you soon", {
          autoClose: 3000,
          onClose: () => {
            setToastClosed(true);
          }
        });
      }
      setShowSpinner(false);

      console.log("Server response:", response.data);


      setFormData({
        pname: "",
        pnumber: "",
        paddress: "",
        ppin: "",
        dpin: "",
        packageType: "",
        orderDate: new Date().toISOString().slice(0, 10),
      });
      setSelectedOption(null);
      setIsOpen(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error");
    }
    finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (toastClosed) {
      Navigate('/');
    }
  }, [toastClosed, Navigate]);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" md:mb-0  mb-[450px]">
      <ToastContainer />
      <div className="bg-white w-full">
        <Link to="/"> <img src="./pick.png" alt="" className="w-52 py-1 pl-6" /></Link>
      </div>

      <div className="main-content">
        <div className="left-side">
          <h1>
            Book your<br />
            <span>orders quickly</span>
          </h1>
          <p>
            Just Enter your Name, Mobile Number, Pickup Pincode, Delivery
            Pincode and it’s done.
          </p>
          <img src="./bg.png" alt="" id="second-image" />
        </div>
        <div className="right-side">
          <h2>
            Add <label>Address</label>
          </h2>
          <div className="mb-4">
            <input
              required
              name="pname"
              value={formData.pname}
              onChange={handleChange}
              className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline"
              type="text"
              placeholder="Pickup Contact Name"
            />
            <input
              required
              name="pnumber"
              value={formData.pnumber}
              onChange={(e) => {

                if (e.target.value.length <= 10) {
                  setFormData({ ...formData, pnumber: e.target.value });
                }
              }}

              className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline"
              type="number"
              placeholder="Pickup Mobile"
            />
            <input
              required
              name="paddress"
              value={formData.paddress}
              onChange={handleChange}
              className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline"
              type="text"
              placeholder="Pickup Address"
            />
            <input
              required
              name="ppin"
              value={formData.ppin}
              onChange={handleChange}
              className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline"
              type="number"
              placeholder="Pickup Pincode *"
            />
            <input
              required
              name="dpin"
              value={formData.dpin}
              onChange={handleChange}
              className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline"
              type="number"
              placeholder="Delivery Pincode *"
            />
          </div>

          <div className="package-details">
            <h3>
              Add <label>Package</label> Details
            </h3>
            <div className="dropdown" ref={dropdownRef}>
              <div className="dropdown-input">
                <input
                  type="text"
                  value={selectedOption || ""}
                  placeholder="Package Type *"
                  readOnly
                  onClick={toggleDropdown}
                />
                <div className="dropdown-icon" onClick={toggleDropdown}>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
              {isOpen && (
                <ul className="dropdown-menu">
                  {options.map((option, index) => (
                    <li key={index} onClick={() => handleOptionClick(option)}>
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {showSpinner ?
            <div className="flex justify-center items-center mt-4">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500 border-r-2 border-b-2 "></div>
            </div> :
            <button type="button" onClick={handleSubmit}>Submit</button>}
        </div>
      </div>
    </div>
  );
};

export default BookNow;
