


import React, { useState } from 'react';
import air from '../../Images/airdelivery.jpg';
import pickupExpress from '../../Images/express1.jpg';
import deliveryData from '../../../updatepin/deliveryPin.json';
import pickupData from '../../../updatepin/pickupPin.json';
import { Link, useNavigate } from 'react-router-dom';

const NewParcel = () => {
    const [formData, setFormData] = useState({
        weight: "",
        deliveryPincode: "",
        pickupPincode: "",
        length: "",
        width: "",
        height: ""
    });
    const [rateData, setRateData] = useState({
        expressRate: null,
        airRate: null,
        volumeRate: 0,
        volumeRateAir: 0,
        chargeableWeight: 0,
    });
    const [showSpinner, setShowSpinner] = useState(false);
    const [cardsVisible, setCardsVisible] = useState(false);
    const [destination, setDestination] = useState({ city: '', state: '' });
    const [pickupDestination, setPickupDestination] = useState({ city: '', state: '' });
    const [expectedDeliveryTime, setExpectedDeliveryTime] = useState({
        express: '',
        air: ''
    });

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        if (name === "pickupPincode") {
            const selectedPickup = pickupData.find(pickup => pickup.pin === value);
            setPickupDestination(selectedPickup ? { city: selectedPickup.city, state: selectedPickup.state } : { city: '', state: '' });
        }

        if (name === "deliveryPincode") {
            const selectedDelivery = deliveryData.find(delivery => delivery.pin === value);
            setDestination(selectedDelivery ? { city: selectedDelivery.city, state: selectedDelivery.state } : { city: '', state: '' });
        }
    };

    const getExpectedDeliveryTime = (destinationType) => {
        const deliveryTimes = {
            p1: { express: "1 - 2 days" },
            p2: { express: "2 - 3 days" },
            p3: { express: "2 - 4 days" },
            p4: { express: "3 - 4 days" },
            p5: { express: "4 - 5 days", air: "2 - 3 days" },
            p6: { express: "4 - 7 days", air: "3 - 5 days" },
            p7: { express: "6 - 8 days", air: "3 - 5 days" },
        };

        return deliveryTimes[destinationType] || { express: 'N/A', air: 'N/A' };
    };

    const calculateVolumetricWeight = (length, width, height, divisor) => Math.ceil((length * width * height) / divisor);

    const calculateRate = () => {
        const { weight, deliveryPincode, pickupPincode, length, width, height } = formData;

        if (!weight || !deliveryPincode || !pickupPincode || !length || !width || !height) {
            alert('Please fill in all fields');
            return;
        }

        setShowSpinner(true);
        setTimeout(() => setShowSpinner(false), 1000);

        const selectedDelivery = deliveryData.find(delivery => delivery.pin === deliveryPincode);
        const selectedPickup = pickupData.find(pickup => pickup.pin === pickupPincode);

        if (!selectedDelivery) {
            alert('Invalid delivery address');
            return;
        }

        if (!selectedPickup) {
            alert('Invalid pickup address');
            return;
        }

        const deliveryTimes = getExpectedDeliveryTime(selectedDelivery.type);
        setExpectedDeliveryTime(deliveryTimes);

        let volumetricWeightExp = 0;
        let volumetricWeightAir = 0;
        let chargeableWeight = Math.ceil(parseFloat(weight));

        if (selectedDelivery.type !== 'p1' && selectedDelivery.type !== 'p3') {
            volumetricWeightExp = calculateVolumetricWeight(length, width, height, 4750);
            volumetricWeightAir = calculateVolumetricWeight(length, width, height, 6000);
            chargeableWeight = Math.max(chargeableWeight, volumetricWeightExp, volumetricWeightAir);
        }

        const rateCalculations = {
            p1: () => calculateRates(chargeableWeight, 80, 40, 35),
            p2: () => calculateRates(chargeableWeight, 100, 60, 55),
            p3: () => calculateRates(chargeableWeight, 100, 60, 60),
            p4: () => calculateRates(chargeableWeight, 120, 70, 65),
            p5: () => calculateRates(chargeableWeight, 120, 80, 80, 170, 170),
            p6: () => calculateRates(chargeableWeight, 140, 100, 100, 180, 180),
            p7: () => calculateRates(chargeableWeight, 150, 120, 110, 200, 200),
        };

        const rates = rateCalculations[selectedDelivery.type]();

        setRateData({
            expressRate: rates.express,
            airRate: rates.air,
            volumeRate: volumetricWeightExp,
            volumeRateAir: volumetricWeightAir,
            chargeableWeight
        });

        setCardsVisible(true);
    };

    const calculateRates = (chargeableWeight, expressBaseRate, expressAdditionalRatePerKg, expressRateAboveTenKg, airBaseRate = 0, airAdditionalRatePerKg = 0, airRateAboveTenKg = 0) => {
        let expressRate;
        if (chargeableWeight <= 1) {
            expressRate = expressBaseRate;
        } else if (chargeableWeight <= 10) {
            expressRate = expressBaseRate + (chargeableWeight - 1) * expressAdditionalRatePerKg;
        } else {
            expressRate = expressBaseRate + (9 * expressAdditionalRatePerKg) + (chargeableWeight - 10) * expressRateAboveTenKg;
        }

        let airRate;
        if (chargeableWeight <= 1) {
            airRate = airBaseRate;
        } else if (chargeableWeight <= 10) {
            airRate = airBaseRate + (chargeableWeight - 1) * airAdditionalRatePerKg;
        } else {
            airRate = airBaseRate + (9 * airAdditionalRatePerKg) + (chargeableWeight - 10) * airRateAboveTenKg;
        }

        return { express: Math.ceil(expressRate), air: Math.ceil(airRate) };
    };

    const handleProceed = (rateType) => {
        const { weight, length, width, height, pickupPincode, deliveryPincode } = formData;
        const packageDetails = { weight, length, width, height ,chargeableWeight : rateData.chargeableWeight};
        const pickupDetails = { pickupPincode, city: pickupDestination.city, state: pickupDestination.state };
        const deliveryDetails = { deliveryPincode, city: destination.city, state: destination.state };
        const expectedDelivery = { express: expectedDeliveryTime.express, air: expectedDeliveryTime.air };
        

        navigate('/form', {
            state: {
                pickupDetails,
                deliveryDetails,
                packageDetails,
                rateType,
                rate: rateType === 'express' ? rateData.expressRate : rateData.airRate,
                expectedDeliveryTime: expectedDelivery[rateType]
            },
        });
    };



    return (
        <div>
            <div className='bg-white p-10 rounded-xl'>
                <div className='lg:grid lg:grid-cols-2 gap-4'>
                    <InputField label="Pickup Pincode*" name="pickupPincode" value={formData.pickupPincode} handleChange={handleInputChange} destination={pickupDestination} />
                    <InputField label="Delivery Pincode*" name="deliveryPincode" value={formData.deliveryPincode} handleChange={handleInputChange} destination={destination} />
                </div>
                <div className="grid lg:grid-cols-4 mt-6 gap-2">
                    <WeightAndDimensionFields formData={formData} handleChange={handleInputChange} />
                </div>
                <button className="bg-blue-800 hover:bg-blue-900 hover:shadow-md text-white font-bold px-10 py-2 md:w-2/4 w-full my-4 rounded" onClick={calculateRate}>Calculate Rate</button>
                <ShippingRateDisplay
                    showSpinner={showSpinner}
                    pickupDestination={pickupDestination}
                    deliveryDestination={destination}
                    cardsVisible={cardsVisible}
                    handleCardClose={() => setCardsVisible(false)}
                    formData={formData}
                    rateData={rateData}
                    expectedDeliveryTime={expectedDeliveryTime}
                    handleProceed={handleProceed}
                />
            </div>
        </div>
    );
};

const InputField = ({ label, name, value, handleChange, destination }) => (
    <div>
        <label className="block text-gray-500 text-sm font-bold mb-2">{label}</label>
        <input className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline" type="text" name={name} value={value} onChange={handleChange} />
        {value.length <= 6 ? <p className="text-green-500 text-[13px] font-medium lowercase">{destination.city} , {destination.state}</p> : <p className="text-red-500">Invalid Pincode</p>}
    </div>
);

const WeightAndDimensionFields = ({ formData, handleChange }) => (
    <>
        <div className="mb-4">
            <label className="block text-gray-500 text-sm font-bold mb-2">Weight (in kg) *</label>
            <input type="number" step="0.01" className="shadow appearance-none border-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-500 focus:shadow-outline" name="weight" value={formData.weight} onChange={handleChange} />
        </div>
        <div className="mb-4">
            <label className="block text-gray-500 text-sm font-bold mb-2">Length (in cm) *</label>
            <input type="number" step="0.01" className="shadow appearance-none border-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-500 focus:shadow-outline" name="length" value={formData.length} onChange={handleChange} />
        </div>
        <div className="mb-4">
            <label className="block text-gray-500 text-sm font-bold mb-2">Width (in cm) *</label>
            <input type="number" step="0.01" className="shadow appearance-none border-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-500 focus:shadow-outline" name="width" value={formData.width} onChange={handleChange} />
        </div>
        <div className="mb-4">
            <label className="block text-gray-500 text-sm font-bold mb-2">Height (in cm) *</label>
            <input type="number" step="0.01" className="shadow appearance-none border-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-500 focus:shadow-outline" name="height" value={formData.height} onChange={handleChange} />
        </div>
    </>
);


const ShippingRateDisplay = ({ showSpinner, cardsVisible, handleCardClose, formData, rateData, expectedDeliveryTime, handleProceed, pickupDestination, deliveryDestination }) => {
    if (showSpinner) return <Spinner />;
    if (!cardsVisible) return null;

    let expressCardVisible = true;
    let airCardVisible = false;

    const deliveryType = deliveryData.find(delivery => delivery.pin === formData.deliveryPincode)?.type;

    if (deliveryType === 'p5' || deliveryType === 'p6' || deliveryType === 'p7') {
        airCardVisible = true;
        expressCardVisible = true;
    }

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {expressCardVisible && (
                <RateCard
                    image={pickupExpress}
                    serviceType="Express"
                    deliveryTime={expectedDeliveryTime.express}
                    rate={rateData.expressRate}
                    weight={formData.weight}
                    volumetricWeight={rateData.volumeRate}
                    chargeableWeight={rateData.chargeableWeight}
                    pickupPincode={formData.pickupPincode}
                    deliveryPincode={formData.deliveryPincode}
                    pickupcity={pickupDestination.city}
                    pickupstate={pickupDestination.state}
                    deliverycity={deliveryDestination.city}
                    deliverystate={deliveryDestination.state}
                    onClose={handleCardClose}
                    onProceed={() => handleProceed('express')}
                />
            )}
            {airCardVisible && (
                <RateCard
                    airCardVisible={airCardVisible}
                    image={air}
                    serviceType="Air"
                    pickupPincode={formData.pickupPincode}
                    deliveryPincode={formData.deliveryPincode}
                    deliveryTime={expectedDeliveryTime.air}
                    rate={rateData.airRate}
                    weight={formData.weight}
                    volumetricWeight={rateData.volumeRateAir}
                    chargeableWeight={rateData.chargeableWeight}
                    pickupcity={pickupDestination.city}
                    pickupstate={pickupDestination.state}
                    deliverycity={deliveryDestination.city}
                    deliverystate={deliveryDestination.state}
                    onClose={handleCardClose}
                    onProceed={() => handleProceed('air')}
                />
            )}
        </div>
    );
};

const Spinner = () => (
    <div className="flex justify-center items-center mt-10">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
    </div>
);

const RateCard = ({ image, serviceType, deliveryTime, rate, volumetricWeight, chargeableWeight, onClose, onProceed, pickupPincode, deliveryPincode, pickupcity, deliverycity, weight, airCardVisible }) => (
    <div className="max-w-sm rounded overflow-hidden shadow-lg relative">
        <button className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 w-6 h-6 flex items-center justify-center text-sm" onClick={onClose}>X</button>
        <img className="w-full" src={image} alt={`${serviceType} delivery`} />
        <div className="px-6 py-4">
            <h2 className="font-bold text-xl text-center mb-2"> Pickupkart {serviceType} </h2>
            <p className="text-gray-500 font-medium text-base mb-2 text-center lowercase"> {pickupPincode}  {pickupcity} - {deliveryPincode}  {deliverycity}</p>
            <p className="text-gray-500 font-medium text-base mb-2 text-center  ">Expected Delivery {deliveryTime}</p>
            {/* <p className="text-gray-700 text-base mb-2">Rate: ₹{rate} /-</p> */}
            <p className="text-gray-500 font-medium text-base mb-2 text-center "> Weight: {weight} kg</p>
            <div className='flex justfy-center item-center ml-14'>
                <p className="text-gray-500 font-medium text-base mb-2 text-center ">Chargeable Weight: {chargeableWeight} kg</p>
                <div className="col-span-1 pl-4 mt-1 relative inline-block group">
                    <svg
                        className="cursor-pointer text-blue-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="16" x2="12" y2="12" />
                        <line x1="12" y1="8" x2="12" y2="8" />
                    </svg>
                    <span className="tooltip-text text-[10px] absolute bg-red-800 text-white text-center p-2 rounded opacity-0 invisible transition-opacity duration-300 -bottom-10 left-1/2 transform -translate-x-1/2 group-hover:opacity-100 group-hover:visible">
                        if the volumetric weight is greter then actual weight so this will become your chargable weight and volumetric weight is calucated by  {airCardVisible ? "l*w*h/6000" : "l*w*h/4750"}
                    </span>
                </div>
            </div>
            <div class=" w-72 lg:w-5/6 m-auto bg-indigo-50 mt-5 p-4 lg:p-4 rounded-2xl">
                <div class="  pt-1">
                    <p class="text-gray-800 text-center font-bold lg:text-xl">Amount : ₹ {rate} </p>
                </div>
            </div>
            {/* <button className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded" onClick={onProceed}>Proceed</button> */}
            <div class="bg-blue-700 w-72 lg:w-5/6 m-auto mt-6 p-2 hover:bg-indigo-500 rounded-2xl cursor-pointer text-white text-center shadow-xl shadow-bg-blue-700" onClick={onProceed}>
                <button classs="lg:text-sm text-lg font-bold"   >Book Order Now</button>
            </div>
        </div>
    </div>
);

export default NewParcel;
