// import React from "react";
// import { Link } from "react-router-dom";

// import twitterlogo from "./Images/twitter-logo.png";
// import pickupkart1 from "./Images/pick.png";
// import fblogo from "./Images/fb-logo.png";
// import instalogo from "./Images/insta-logo.png";
// import icons8hamburger from "./Images/icons8-hamburger-menu-24.png";
// import { Menu } from "@mui/material";
// import { IconButton } from "@mui/material";

// const Nav = () => {
//     return (
//         <>
//             {/* <!-- navbar --> */}
//             <div className="w-full   h-full bg-gradient-to-l from-purple-200 ">
//                 <div className="flex h-20 flex-row gap-6 lg:grid lg:grid-cols-3 items-center max-[640px]:gap-2">
//                     <div className="col-span-1 pl-10 "> <Link to={'/'}> <img src={pickupkart1} className="max-[640px]:-ml-5 -ml-3 max-[768px]:w-[160px]" width="200" alt="" /></Link></div>


//                     <div className=" flex lg:cols-span-2  gap-[40px] ml-4 mr-8">
//                         <Link className="text-[16px] text-stone-500	 font-semibold hover:text-blue-400  max-lg:hidden" to="/">Home</Link>
//                         <Link className="text-[16px] text-stone-500 font-semibold hover:text-blue-400 max-lg:hidden" to="/About">About Us</Link>
//                         <Link className="text-[16px] text-stone-500 font-semibold hover:text-blue-400 max-lg:hidden" to="/Services">Service</Link>
//                         <Link className="text-[16px] text-stone-500 font-semibold hover:text-blue-400 max-lg:hidden pr-8" to="/BookNow">Contact us</Link>
//                         <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO hidden pr-8" to="/DocumentForm">CONTACT US</Link>
//                         <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO hidden pr-8" to="/Contact">CONTACT US</Link>
//                         <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO hidden pr-8" to="/Contact">CONTACT US</Link>
//                         <span id="hamgurgerBtn" src={icons8hamburger} height="50px" className="lg:hidden" alt="" onClick={() => {
//                             let hiddenSection = document.querySelector("#hamburger");
//                             let hamburgerButton = document.querySelector("#hamburgerBtn");
//                             hiddenSection.classList.toggle("active");
//                         }} ><i className="fa-solid fa-bars text-[#0260AA]"></i></span>
//                     </div>

//                     <div className="flex mx-auto  hidden col-span-1 lg:block ">


//                         <div className="flex mx-auto flex-row  items-center max-[640px]:gap-2">
//                             <IconButton
//                                 variant="contained" disableRipple
//                                 sx={{ borderRadius: '50%', backgroundColor: "#0260AA", width: "30px", height: "30px" }}
//                             >
//                                 <i className="text-[white] text-[16px] fa-brands fa-facebook-f  "></i>
//                             </IconButton>
//                             <IconButton
//                                 variant="contained" disableRipple
//                                 sx={{ borderRadius: '50%', backgroundColor: "#0260AA", width: "30px", height: "30px", marginLeft: "20px" }}
//                             >
//                                 <i className="text-[white] text-[16px] fa-brands fa-instagram  "></i>
//                             </IconButton>
//                             <IconButton
//                                 variant="contained" disableRipple
//                                 sx={{ borderRadius: '50%', backgroundColor: "#0260AA", width: "30px", height: "30px", marginLeft: "20px" }}
//                             >
//                                 <i className="text-[white] text-[16px] fa-brands fa-twitter  "></i>
//                             </IconButton>
//                         </div>



//                     </div>


//                 </div>

//                 {/* <!-- Hamburger section --> */}
//                 <div className="ml-40">
//                 <div className="hidden   " id="hamburger" >
//                     <div className="w-full h-full bg-transparent flex flex-col pl-12 pb-5 gap-10">
//                         <Link className="text-[16px] mt-5 text-[#0260AA] font-semibold font-ROBOTO" to="/">HOME</Link>
//                         <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO" to="/About">ABOUT US</Link>
//                         <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO" to="/Services">SERVICES</Link>
//                         <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO = pr-8" to="/Contact">CONTACT US</Link>

//                     </div>
//                 </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Nav;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import twitterlogo from "./Images/twitter-logo.png";
import pickupkart1 from "./Images/pick.png";
import fblogo from "./Images/fb-logo.png";
import instalogo from "./Images/insta-logo.png";
import icons8hamburger from "./Images/icons8-hamburger-menu-24.png";
import { Menu } from "@mui/material";
import { IconButton } from "@mui/material";

const Nav = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header className={`w-full fixed top-0 z-10 bg-gradient-to-l from-purple-200 ${isScrolled ? 'shadow-md bg-white z-10' : ''}`}>
            <div className="flex h-20 flex-row gap-6 lg:grid lg:grid-cols-3 items-center max-[640px]:gap-2">
                <div className="col-span-1 pl-10 "> <Link to={'/'}> <img src={pickupkart1} className="max-[640px]:-ml-5 -ml-3 max-[768px]:w-[160px]" width="200" alt="" /></Link></div>


                <div className=" flex lg:cols-span-1 gap-[60px] mx-auto ">
                    <Link className="text-[16px] text-stone-500	 font-semibold hover:text-blue-400  max-lg:hidden" to="/">Home</Link>
                    {/* <Link className="text-[16px] text-stone-500 font-semibold hover:text-blue-400 max-lg:hidden" to="/About">About Us</Link> */}
                    <Link className="text-[16px] text-stone-500 font-semibold hover:text-blue-400 max-lg:hidden" to="/Services">Service</Link>
                    <Link className="text-[16px] text-stone-500 font-semibold hover:text-blue-400 max-lg:hidden pr-8" to="/Contact">Contact us</Link>

                    <span id="hamgurgerBtn" src={icons8hamburger} height="50px" className="lg:hidden ml-20" alt="" onClick={() => {
                        let hiddenSection = document.querySelector("#hamburger");
                        let hamburgerButton = document.querySelector("#hamburgerBtn");
                        hiddenSection.classList.toggle("active");
                    }} ><i className="fa-solid fa-bars text-[#0260AA]"></i></span>
                </div>

                <div className="flex mx-auto  hidden col-span-1 lg:block ">


                    <div className="flex mx-auto flex-row  items-center max-[640px]:gap-2">
                        <IconButton
                            variant="contained" disableRipple
                            sx={{ borderRadius: '50%', backgroundColor: "#0260AA", width: "30px", height: "30px" }}
                        >
                            <i className="text-[white] text-[16px] fa-brands fa-facebook-f  "></i>
                        </IconButton>
                        <IconButton
                            variant="contained" disableRipple
                            sx={{ borderRadius: '50%', backgroundColor: "#0260AA", width: "30px", height: "30px", marginLeft: "20px" }}
                        >
                            <i className="text-[white] text-[16px] fa-brands fa-instagram  "></i>
                        </IconButton>
                        <IconButton
                            variant="contained" disableRipple
                            sx={{ borderRadius: '50%', backgroundColor: "#0260AA", width: "30px", height: "30px", marginLeft: "20px" }}
                        >
                            <i className="text-[white] text-[16px] fa-brands fa-twitter  "></i>
                        </IconButton>
                    </div>



                </div>


            </div>

            {/* <!-- Hamburger section --> */}
            <div className="">
                <div className="hidden   " id="hamburger" >
                    <div className="w-full h-full bg-white flex flex-col pl-12 pb-5 gap-10">
                        <Link className="text-[16px] mt-5 text-[#0260AA] font-semibold font-ROBOTO" to="/">HOME</Link>
                        {/* <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO" to="/About">ABOUT US</Link> */}
                        <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO" to="/Services">SERVICES</Link>
                        <Link className="text-[16px] text-[#0260AA] font-semibold font-ROBOTO = pr-8" to="/Contact">CONTACT US</Link>
                        <Link to="/BookNow"> <a class="box-border relative  inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-indigo-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                            <span class="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                            <span class="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                            <span class="relative  flex items-center text-sm">
                                <svg class="relative w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
                                Quick Book
                            </span>
                        </a></Link>
                        <div>

                        </div>

                    </div>
                </div>
            </div>
        </header>
    )
}

export default Nav;
