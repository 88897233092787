


import React, { useState } from "react";
import Toggle from "./Toggle";
// import Document from './Document';
import PickupParcel from "./PickupParcel";
import PickupCargo from "./PickupCargo";
import Documents from "./Documents";
import BookNow from "./BookNow";
import { Link } from "react-router-dom";
import RateCalculator from "./NewDocument";
import NewDocument from "./NewDocument";
import NewParcel from "./NewParcel";

const PickupMode = () => {
  const [activeButton, setActiveButton] = useState("Document");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === "Call Now") {
      window.open("tel:+917350926425");
    }
  };

  return (
    <div className="  w-full  ">

      <div className="   z-10 md:mx-40 lg:mx-80   ">
       
        <div className="justify-between flex mr-4">
          <div className="flex">
            <Toggle
              label="Document"
              active={activeButton === "Document"}
              onClick={() => handleButtonClick("Document")}
            />
            <Toggle
              label="Parcel"
              active={activeButton === "Parcel"}
              onClick={() => handleButtonClick("Parcel")}
            />
            <Toggle
              label="Cargo"
              active={activeButton === "Cargo"}
              onClick={() => handleButtonClick("Cargo")}
            />
          </div>
          <div>
            <Link to="/BookNow"> <a class="inline-flex hidden sm:block items-center justify-center w-full px-6 py-2 mb-2 text-lg text-white bg-green-500 rounded-md hover:bg-green-400 sm:w-auto sm:mb-0" data-primary="green-400" data-rounded="rounded-2xl" data-primary-reset="{}">
              Quick Book
            </a>
            </Link>
          </div>
        </div>
        <div className="mt-2">
          {activeButton === "Document" && (
            <div>
              {/* <Documents /> */}
              <NewDocument/>
            </div>
          )}
          {activeButton === "Parcel" && (
            <div>
            
              {/* <PickupParcel /> */}
              <NewParcel/>
            </div>
          )}
          {activeButton === "Cargo" && (
            <div>
            
              <PickupCargo />
            </div>
          )}
          <p className="text-black mb-10 text-sm mt-6 ">
            Note : Please enter the correct parcel weight and dimensions.
            Failure to do so may result in delivery delays and additional
            charges.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PickupMode;
