


import React, { useEffect } from 'react';
import { BrowserRouter, HashRouter, Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import Main from './component/Home/Main';
import Parsal from './component/Other/Parsal';
import Report from './component/Other/Report';
import Services from './component/Services';
import About from './component/About';
import Contact from './component/Contact';
import Nav from './component/Nav';
import Footer from './component/Footer';
import QuickForm from './component/QuickOrders/QuickForm';

import FormPage from './component/Home/component/FormPage';
import CargoOrdersummery from './component/Home/component/CargoOrdersummery';
import DocumentFormpage from './component/Home/component/DocumentFormpage';
import RestractedItem from './component/restractedItem/RestractedItem';
import BookNow from './component/Home/component/BookNow';
import OrderTracking from './component/trackingdetail/OrderTracking';
import SubmitDetails from './component/trackingdetail/SubmitDetails';
import ThankYouPage from './component/OrderConfirm/OrderConfirm';
import PrivacyPolicy from './component/privacy/Privacy';
import TrackingWhtasapp from './component/OrderTrackingWhtasapp/TrackingWhtasapp';

function ScrollToTopOnPageChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handlePopstate = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  return null;
}


// function scrollToTop() {
//   window.scrollTo({
//     top: 0,
//     behavior: 'smooth' // Optional: Add smooth scrolling behavior
//   });
// }

// // Add an event listener to handle the popstate event
// window.addEventListener('popstate', function(event) {
//   // Scroll to the top when the back button is clicked
//   scrollToTop();
// });

function App() {
  return (
    <HashRouter>
      {/* <Nav /> */}
      <ScrollToTopOnPageChange />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Parsal" element={<Parsal />} />
        <Route path="/Report" element={<Report />} />
        <Route path="/Restricted-Item" element={<RestractedItem />} />
        <Route path="/BookNow" element={<BookNow />} />
        <Route path='/DocumentForm' element={<DocumentFormpage />} />
        <Route path='/quick' element={<QuickForm />} />
        <Route path='/form' element={<FormPage />} />
        <Route path='/cargo-order' element={<CargoOrdersummery />} />
        <Route path='/Tracking' element={<OrderTracking />} />
        <Route path="/track/:order_id" element={<TrackingWhtasapp />} />
        {/* <Route path="/api/track?order_id=:order_id" element={<TrackingWhtasapp />} /> */}
        <Route path='/Orders_tracking' element={<SubmitDetails />} />
        <Route path='/Orders-confirm' element={<ThankYouPage />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
      </Routes>

      <Footer />
    </HashRouter>
  );
}

export default App;
