import React from 'react';
import Nav from '../Nav';
import { useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTruck, faBoxOpen } from "@fortawesome/free-solid-svg-icons";

const statusIcons = {
    "DELIVERED": faCheckCircle,
    "IN_TRANSIT": faTruck,
    "OUT_FOR_DELIVERY": faTruck,
    "ORDER_PLACED": faBoxOpen
};

const statusColors = {
    "DELIVERED": "text-green-500",
    "IN_TRANSIT": "text-blue-500",
    "OUT_FOR_DELIVERY": "text-yellow-500",
    "ORDER_PLACED": "text-gray-500"
};

const OrderTracking = () => {

    const location = useLocation();
    const { info } = location.state || {};

    const { awbno, pickupdate, time, order_id, recipient, from, to, current_status, scan, carrier } = info;

    console.log(info);

    if (!info) {
        return <p>No tracking information available.</p>;
    }

    return (
        <div>
            <Nav />
            <div className="p-6 max-w-7xl mx-auto md:bg-gray-50 my-20 lg:my-40 rounded-xl shadow-md ">
                <div className="">
                    <div className='flex item-center justify-center'>
                        <h1 className="lg:text-3xl text-2xl text-gray-700 text-center   font-bold ">STATUS -  </h1>
                        <h2 className={`lg:text-3xl text-2xl ml-4 text-center  font-semibold ${current_status === "Delivered" ? "text-green-500" : "text-blue-500"}`}>{current_status == "Delivered" ? current_status : "In-transist"}</h2>
                    </div>
                    <p className="text-lg lg:block hidden text-gray-500 my-8 text-center">
                        Please note that these are accurate but not guaranteed estimates. Delivery date is subject to change without advanced notice.
                    </p>
                </div>


                <div class="bg-white rounded-lg p-4 space-y-2 border-collapse border-none hidden md:block">
                    <table class="table-auto w-full border-none border-collapse">
                        <thead className='border-none border-collapse'>
                            <tr>
                                {recipient === "" ? "" : <th class="uppercase text-gray-500 border-none">Recipient</th>}
                                <th class="uppercase text-gray-500 border-none uppercase">carrier</th>
                                <th class="uppercase text-gray-500 border-none uppercase">awb no</th>

                                {from == null || to == null ? "" : <th class="uppercase text-gray-500 border-none">FROM</th>}
                                {from == null || to == null ? "" : <th class="uppercase text-gray-500 border-none">SHIP TO</th>}

                                <th class="uppercase text-gray-500 border-none">ORDER ID</th>
                            </tr>
                        </thead>
                        <tbody className='border-none'>
                            <tr>
                                {recipient === "" ? "" : <td class="border-none"> {recipient} </td>}
                                <td class="border-none"> {carrier} </td>
                                <td class="border-none"> {awbno} </td>
                                {from == null || to == null ? " " : <td class="border-none"> {from} </td>}
                                {from == null || to == null ? " " : <td class="border-none"> {to} </td>}
                                <td class="border-none"> #{order_id} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* <div className="">

                    <div className="flex mt-10 items-center justify-between">
                        <div className="flex-1 flex flex-col items-center">
                            <div className="bg-green-600 text-white rounded-full h-8 w-8 flex items-center justify-center">✓</div>
                            <span className="text-xs mt-2">Order Placed</span>
                            <span className="text-xs text-gray-500">May 30, 2021</span>
                        </div>
                        <div className="flex-1 flex justify-center">
                            <div className="h-px w-full bg-green-600"></div>
                        </div>
                        <div className="flex-1 flex flex-col items-center">
                            <div className="bg-green-600 text-white rounded-full h-8 w-8 flex items-center justify-center">✓</div>
                            <span className="text-xs mt-2">In Production</span>
                            <span className="text-xs text-gray-500">May 31, 2021</span>
                        </div>
                        <div className="flex-1 flex justify-center">
                            <div className="h-px w-full bg-green-600"></div>
                        </div>
                        <div className="flex-1 flex flex-col items-center">
                            <div className="bg-green-600 text-white rounded-full h-8 w-8 flex items-center justify-center">✓</div>
                            <span className="text-xs mt-2">Ocean Transit</span>
                            <span className="text-xs text-gray-500">June 29, 2021</span>
                        </div>



                    </div>
                </div> */}

                {/* <div className='flex item-center justify-center space-x-4 text-lg  md:hidden text-center mt-4'>
                    <h3> {carrier}  </h3>

                    <h3> {awbno}  </h3>

                </div>
                {
                    from == null || to == null ?
                        "" : <div className='flex item-center justify-center space-x-4 text-lg  md:hidden text-center mt-4'>
                            <h3>{from} </h3>
                            <h3>To</h3>
                            <h3 className=''> {to}  </h3>
                        </div>
                } */}

                {/* <div className='flex item-center justify-center space-x-4 text-lg  md:hidden text-center mt-4'>
                    <h3>{from} </h3>
                    <h3>To</h3>
                    <h3 className=''> {to}  </h3>
                </div> */}


                <div className=" mx-auto p-4 md:hidden">
                    <table className="min-w-full  border border-gray-200">
                        <tbody>

                            {recipient === "" ? "" :
                                <tr className="border-b ">
                                    <th className="text-left px-3 py-2 border-r">Recipient</th>
                                    <td className="text-left bg-yellow-50 px-3 py-2">{recipient}</td>
                                </tr>}


                            <tr className="border-b">
                                <th className="text-left px-3 py-2 bg-green-50 border-r">Carrier</th>
                                <td className="text-left px-3 bg-yellow-50 py-2">{carrier}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="text-left px-3 bg-green-50  py-2 border-r">awb no</th>
                                <td className="text-left px-3 bg-yellow-50 py-2">{awbno}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="text-left px-3 bg-green-50 py-2 border-r">Order id</th>
                                <td className="text-left px-3 bg-yellow-50 py-2">{order_id}</td>
                            </tr>

                            {from == null || to == null ? " " : <tr className="border-b">
                                <th className="text-left px-3 py-2 bg-green-50  border-r">From</th>
                                <td className="text-left px-3 bg-yellow-50 py-2">{from}</td>
                            </tr>}
                            {from == null || to == null ? " " : <tr className="border-b">
                                <th className="text-left px-3 bg-green-50  py-2 border-r">To</th>
                                <td className="text-left px-3 bg-yellow-50  py-2">{to}</td>
                            </tr>}

                        </tbody>
                    </table>
                </div>

                <div className="   lg:p-6 rounded-lg   ">

                    <div  >
                        <h2 className="md:text-xl mt-10  font-semibold ">Tracking Details</h2>
                        <div className="lg:grid lg:grid-cols-4">
                            {scan.map((event, index) => (

                                <div key={index} className=" flex md:items-center lg:px-4 lg:py-4 py-4">
                                    <div>
                                        <p className='text-sm md:hidden font-medium text-blue-600 mb-2'> {event.time.substring(11, 19)}</p> {/* This will give you the time */}
                                        {/* <p className='text-sm md:hidden  text-blue-600'> {event.time}</p> */}
                                        <FontAwesomeIcon icon={statusIcons[event.status_detail.toUpperCase()] || faTruck} className={`mr-6 flex text-2xl ${statusColors[event.status_detail.toUpperCase()] || "text-gray-500"}`} />
                                    </div>

                                    <div className=' lg:pl-1 pl-10'>
                                        <p className='text-sm md:hidden font-medium text-blue-600'> {event.time.substring(0, 10)}</p> {/* This will give you the date */}

                                        <p className='text-lg hidden md:block text-blue-600'> {event.time}</p>
                                        <p className='md:text-lg lowercase'>{event.status_detail}</p>
                                        <p className='md:text-lg lowercase'>{event.location}</p>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default OrderTracking;
