

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import TrackingInfo from './TrackingInfo';
import OrderTracking from '../../../trackingdetail/OrderTracking';
import { getOrderShipmentDetails } from '../../../trackingdetail/Api';

function Trackship() {
    const [orderId, setOrderId] = useState('');
    const [trackingInfo, setTrackingInfo] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); 
    const navigate = useNavigate();

    const handleTrack = async () => {
        if (!orderId) { 
            setError('Order ID is required');
            return;
        }

        setLoading(true); 
        try {
            const data = await getOrderShipmentDetails(orderId);
            if (data.status === 'Success') {
                setTrackingInfo(data.response);
                const info = data.response;
                navigate("/Tracking", { state: { info } });
                setError(null);
            } else {
                setTrackingInfo(null);
                setError('No tracking information available.');
            }
        } catch (err) {
            setTrackingInfo(null);
            setError('Failed to track parcel. Please check the order ID and try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="flex items-center mt-1">
                <input
                    required
                    type="text"
                    className="h-11 px-3 text-sm text-gray-700 border border-r-0 border-blue-500 focus:outline-none rounded-full rounded-r shadow-sm"
                    placeholder="ex- 110001"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                />
                {!loading &&
                    <button
                        onClick={handleTrack}
                        className="h-11 md:px-4 px-7 lg:mt-0 text-sm bg-red-500 border border-l-0 border-red-500 rounded-l rounded-full shadow-sm text-blue-50 hover:text-white hover:bg-blue-400 hover:border-blue-400 focus:outline-none text-xs"
                    >
                        Track shipment
                    </button>
                }
                {loading && <div className="loader">
                    <button
                        className="h-11 md:px-4 px-7 lg:mt-0 text-sm bg-red-500 border border-l-0 border-red-500 rounded-l rounded-full shadow-sm text-blue-50 hover:text-white hover:bg-blue-400 hover:border-blue-400 focus:outline-none text-xs"
                    >
                        Tracking....
                    </button>
                    {/* {error && <div className="text-red-500">{error}</div>} */}

                </div>

                }
            </div>

        </div>
    );
}

export default Trackship;
