import React, { useState } from 'react';
import air from '../../Images/airdelivery.jpg'
import pickupExpress from '../../Images/express1.jpg'
import deliveryData from '../../../updatepin/deliveryPin.json';
import pickupData from '../../../updatepin/pickupPin.json';
import { Link, useNavigate } from 'react-router-dom';



const NewDocument = () => {
    const [weight, setWeight] = useState("");
    const [deliveryPincode, setDeliveryPincode] = useState('');
    const [PickupPincode, setPickupPincode] = useState('');
    const [expressRate, setExpressRate] = useState(null);
    const [airRate, setAirRate] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [cardsVisible, setCardsVisible] = useState(false);
    const [destination, setDestination] = useState({ city: '', state: '' });
    const [pickupDestination, setPickupDestination] = useState({ city: '', state: '' });
    const [expressExpectedDeliveryTime, setExpressExpectedDeliveryTime] = useState('');
    const [airExpectedDeliveryTime, setAirExpectedDeliveryTime] = useState('');


    const handleCardClose = () => {
        setCardsVisible(false);
        setShowSpinner(false);
    };



    const handleWeightChange = (event) => {
        setWeight(Number(event.target.value));
    };

    const handlePickupPincodeChange = (event) => {
        setPickupPincode(event.target.value);
        const selectedPickup = pickupData.find((pickup) => pickup.pin === event.target.value);
        if (selectedPickup) {
            setPickupDestination({ city: selectedPickup.city, state: selectedPickup.state });

        } else {
            setPickupDestination('');
        }
    };

    const handleDeliveryPincodeChange = (event) => {
        setDeliveryPincode(event.target.value);
        const selectedDelivery = deliveryData.find((delivery) => delivery.pin === event.target.value);
        if (selectedDelivery) {
            setDestination({ city: selectedDelivery.city, state: selectedDelivery.state });

        } else {
            setDestination('');
        }
    };

    const getExpectedDeliveryTime = (destinationType) => {
        switch (destinationType) {
            case 'p1':
                return {
                    express: "1 - 2 days"
                };
            case 'p2':
                return {
                    express: "2 - 3 days"
                };
            case 'p3':
                return {
                    express: "2 - 4 days"
                };
            case 'p4':
                return {
                    express: "3 - 4 days"
                };
            case 'p5':
                return {
                    express: "4 - 5 days",
                    air: "2 - 3 days"
                };
            case 'p6':
                return {
                    express: "4 - 7 days",
                    air: "3 - 5 days"
                };
            case 'p7':
                return {
                    express: "6 - 8 days",
                    air: "3 - 5 days"
                };
            default:
                return 'N/A';
        }
    };


    console.log("setDestination", destination.city)

    const calculateRate = () => {
        if (!weight || !deliveryPincode || !destination || !PickupPincode || !pickupDestination) {
            alert('Please fill in all fields');
            return;
        }


        setShowSpinner(true);

        setTimeout(() => {
            setShowSpinner(false);
        }, 1000);



        const selectedDelivery = deliveryData.find(
            (delivery) => delivery.pin === deliveryPincode
        );
        const selectedPickup = pickupData.find(
            (pickup) => pickup.pin === PickupPincode
        );

        if (!selectedDelivery) {
            alert('Invalid delivery address');
            return;
        }

        if (!selectedPickup) {
            alert('Invalid pickup address');
            return;
        }

        const deliveryTimes = getExpectedDeliveryTime(selectedDelivery.type);
        setExpressExpectedDeliveryTime(deliveryTimes.express);
        setAirExpectedDeliveryTime(deliveryTimes.air);

        let expressRate = 0;
        let airRate = 0;

        switch (selectedDelivery.type) {
            case 'p1':
                expressRate = calculateRateForP1();
                break;
            case 'p2':
                expressRate = calculateRateForP2();
                break;
            case 'p3':
                expressRate = calculateRateForP3();
                break;
            case 'p4':
                expressRate = calculateRateForP4();
                break;
            case 'p5':
                expressRate = calculateRateForP5('express');
                airRate = calculateRateForP5('air');
                break;
            case 'p6':
                expressRate = calculateRateForP6('express');
                airRate = calculateRateForP6('air');
                break;
            case 'p7':
                expressRate = calculateRateForP7('express');
                airRate = calculateRateForP7('air');
                break;
            default:
                alert('Unsupported destination type');
                return;
        }

        setExpressRate(expressRate);
        setAirRate(airRate);
        setCardsVisible(true);
    };

    const calculateRateForP1 = () => {
        let rate = 0;
        if (weight <= 250) {
            rate = 60;
        } else if (weight <= 1000) {
            rate = 80;
        } else {
            window.alert("document accept only upto 1000 gram for more waight go for parcel")
        }
        return rate
    };

    const calculateRateForP2 = () => {
        let rate = 0;
        if (weight <= 250) {
            rate = 80;
        } else if (weight <= 1000) {
            rate = 100;
        } else {
            window.alert("document accept only upto 1000 gram for more waight go for parcel")
        }
        return rate
    };

    const calculateRateForP3 = () => {
        let rate = 0;
        if (weight <= 250) {
            rate = 80;
        } else if (weight <= 1000) {
            rate = 100;
        } else {
            window.alert("document accept only upto 1000 gram for more waight go for parcel")
        }
        return rate
    };
    const calculateRateForP4 = () => {
        let rate = 0;
        if (weight <= 250) {
            rate = 100;
        } else if (weight <= 1000) {
            rate = 120;
        } else {
            window.alert("document accept only upto 1000 gram for more waight go for parcel")
        }
        return rate
    };

    const calculateRateForP5 = (pickupType) => {
        let rate = 0;
        if (pickupType === 'express') {
            if (weight <= 250) {
                rate = 0;
            } else if (weight <= 1000) {
                rate = 120;
            } else {
                window.alert("document accept only upto 1000 gram for more waight go for parcel")
            }

        } else if (pickupType === 'air') {
            if (weight <= 250) {
                rate = 120;
            } else if (weight <= 1000) {
                rate = 170;
            } else {
                window.alert("document accept only upto 1000 gram for more waight go for parcel")
            }
        }
        return rate;
    };
    const calculateRateForP6 = (pickupType) => {
        let rate = 0;
        if (pickupType === 'express') {
            if (weight <= 250) {
                rate = 0;
            } else if (weight <= 1000) {
                rate = 140;
            } else {
                window.alert("document accept only upto 1000 gram for more waight go for parcel")
            }

        } else if (pickupType === 'air') {
            if (weight <= 250) {
                rate = 130;
            } else if (weight <= 1000) {
                rate = 180;
            } else {
                window.alert("document accept only upto 1000 gram for more waight go for parcel")
            }
        }
        return rate;
    };

    const calculateRateForP7 = (pickupType) => {
        let rate = 0;
        if (pickupType === 'express') {
            if (weight <= 250) {
                rate = 0;
            } else if (weight <= 1000) {
                rate = 150;
            } else {
                window.alert("document accept only upto 1000 gram for more waight go for parcel")
            }

        } else if (pickupType === 'air') {
            if (weight <= 250) {
                rate = 140;
            } else if (weight <= 1000) {
                rate = 200;
            } else {
                window.alert("document accept only upto 1000 gram for more waight go for parcel")
            }
        }
        return rate;
    };

    const navigate = useNavigate();


    const handleExpressProceed = () => {
        const pickupDetails = { PickupPincode, pickupDestination };
        const deliveryDetails = { deliveryPincode, destination };
        const packageDetails = { weight };
        const expectedDeliveryTime = { expressExpectedDeliveryTime };

        navigate("/DocumentForm", { state: { pickupDetails, deliveryDetails, packageDetails, expressRate, expectedDeliveryTime } });
    };

    const handleAirProceed = () => {
        const pickupDetails = { PickupPincode, pickupDestination };
        const deliveryDetails = { deliveryPincode, destination };
        const packageDetails = { weight };
        const expectedDeliveryTime = { airExpectedDeliveryTime };


        navigate("/DocumentForm", { state: { pickupDetails, deliveryDetails, packageDetails, airRate, expectedDeliveryTime } });
    };


    return (
        <div >
            <div className='bg-white   p-10 rounded-xl'>
                <div className='lg:grid lg:grid-cols-2 gap-4'>

                    <div>
                        <label className="block text-gray-500 text-sm font-bold mb-2">  Pickup Pincode*       </label>

                        <input className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline" type="text" value={PickupPincode} onChange={handlePickupPincodeChange} />

                        {PickupPincode.length <= 6 ? <p className="text-green-500 text-[13px] font-medium lowercase"> {pickupDestination.city}</p> : <p className="text-red-500">Invalid Pincode</p>}

                    </div>

                    <div>
                        <label className="block text-gray-500 text-sm font-bold mb-2"> Delivery Pincode* </label>

                        <input className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline" type="text" value={deliveryPincode} onChange={handleDeliveryPincodeChange} />

                        {deliveryPincode.length <= 6 ? <p className="text-green-500 text-[13px] font-medium lowercase"> {destination.city}</p> : <p className="text-red-500">Invalid Pincode</p>}

                    </div>
                    <div>
                        <label className="block text-gray-500 text-sm font-bold mb-2"> Weight (upto 1000 grams):   </label>

                        <input className="shadow appearance-none border-2 border-gray-300 text-gray-700 rounded w-full py-2 px-3 leading-tight focus:outline-blue-500 focus:shadow-outline" type="number" value={weight} onChange={handleWeightChange} />

                    </div>

                    <button className="bg-blue-800  hover:bg-blue-900 hover:shadow-md text-white font-bold px-10  py-2 md:w-3/4  w-full  my-4 rounded" onClick={calculateRate}>Calculate Rate</button>
                </div>

                <div className="mt-4   ">
                    <div className=" justify-between mr-2 flex ">

                        <p className="font-bold text-gray-500">Shipping Rate:</p>
                        <Link to='/Restricted-Item' className="font-bold text-red-600">Restricted Item*</Link>

                    </div>
                    {showSpinner && (
                        <div className="flex justify-center items-center mt-4">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500 border-r-2 border-b-2 "></div>
                        </div>
                    )}
                    {!showSpinner && cardsVisible && (
                        <div>
                            <div>
                                <div className="mt-6 ">
                                    <button className="lg:text-sm px-6 py-2 border rounded-lg bg-red-500 text-white text-lg font-bold" onClick={handleCardClose}>
                                        Close
                                    </button>
                                </div>
                                <div className='lg:grid lg:grid-cols-2'>

                                    {deliveryPincode && airRate > 0 && (
                                        <div class="w-80 mt-10 m-auto lg:mt-16 max-w-sm">
                                            <img src={air} alt="" class="rounded-t-2xl shadow-2xl lg:w-full 2xl:w-full 2xl:h-36 object-cover" />
                                            <div class="bg-white pb-10 shadow-md border rounded-xl">
                                                <h2 class="text-center text-gray-800 text-2xl font-bold pt-6">PickupKart Air</h2>
                                                <div class=" m-auto">
                                                    <p class="text-center text-gray-500 pt-5"> {PickupPincode} {pickupDestination.city}  -  {deliveryPincode} {destination.city}  </p>
                                                    <p class=" text-gray-500 text-center pt-1"> Weight : {weight} g</p>
                                                    <p class=" text-gray-500 text-center pt-1"> Expected delivery : {airExpectedDeliveryTime}</p>

                                                </div>
                                                <div class=" w-72 lg:w-5/6 m-auto bg-indigo-50 mt-5 p-4 lg:p-4 rounded-2xl">
                                                    <div class="  pt-1">
                                                        <p class="text-gray-800 text-center font-bold lg:text-xl">Amount : ₹ {airRate} </p>
                                                    </div>
                                                </div>
                                                <div class="bg-blue-700 w-72 lg:w-5/6 m-auto mt-6 p-2 hover:bg-indigo-500 rounded-2xl  text-white cursor-pointer text-center shadow-xl shadow-bg-blue-700" onClick={() => handleAirProceed(airRate)}>
                                                    <button classs="lg:text-sm text-lg font-bold"  >Book Order Now</button>
                                                </div>
                                            </div>
                                        </div>

                                    )}

                                    {deliveryPincode && expressRate > 0 && (

                                        <div class="w-80 mt-10 m-auto lg:mt-10 max-w-sm">
                                            <img src={pickupExpress} alt="" class="rounded-t-2xl shadow-2xl lg:w-full 2xl:w-full 2xl:h-36 object-cover" />

                                            <div class="bg-white pb-10 shadow-md border rounded-xl">
                                                <h2 class="text-center text-gray-800 text-2xl font-bold pt-6">PickupKart Express</h2>
                                                <div class=" m-auto">
                                                    <p class="text-center text-gray-500 lowercase pt-5">  {PickupPincode} {pickupDestination.city}   -  {deliveryPincode} {destination.city}</p>
                                                    <p class=" text-gray-500 text-center pt-1"> Weight : {weight} g</p>
                                                    <p class=" text-gray-500 text-center pt-1"> Expected delivery : {expressExpectedDeliveryTime}</p>
                                                </div>
                                                <div class=" w-72 lg:w-5/6 m-auto bg-indigo-50 mt-5 p-4 lg:p-4 rounded-2xl">
                                                    <div class="  pt-1">
                                                        <p class="text-gray-800 text-center font-bold lg:text-xl">Amount : ₹ {expressRate} </p>
                                                    </div>
                                                </div>
                                                <div class="bg-blue-700 w-72 lg:w-5/6 m-auto mt-6 p-2 hover:bg-indigo-500 rounded-2xl cursor-pointer text-white text-center shadow-xl shadow-bg-blue-700" onClick={() => handleExpressProceed(expressRate)}>
                                                    <button classs="lg:text-sm text-lg font-bold"  >Book Order Now</button>
                                                </div>
                                            </div>
                                            <div><p className=" text-[14px] mt-2"><strong>Note :</strong> package will pickup till 4pm After that it will go for  next day   also pickup is not acceptable in sunday  but it will picked-up next working days</p></div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default NewDocument;
