// // src/TrackParcel.js

// import React, { useState } from 'react';
// import { getOrderShipmentDetails } from './Api';
// import Nav from '../Nav';

// const TrackParcel = () => {
//   const [orderId, setOrderId] = useState('');
//   const [trackingInfo, setTrackingInfo] = useState(null);
//   const [error, setError] = useState(null);

//   const handleTrack = async () => {
//     try {
//       const data = await getOrderShipmentDetails(orderId);
//       if (data.status === 'Success') {
//         setTrackingInfo(data.response);
//         setError(null);
//       } else {
//         setTrackingInfo(null);
//         setError('No tracking information available.');
//       }
//     } catch (err) {
//       setTrackingInfo(null);
//       setError('Failed to track parcel. Please check the order ID and try again.');
//     }
//   };

//   return (
//    <div>
// <Nav/>
//      <div className='my-32'>
//       <h2>Track Your Parcel</h2>
//       <div>
//         <label>
//           Order ID:
//           <input
//             type="text"
//             className='border border-gray-500'
//             value={orderId}
//             onChange={(e) => setOrderId(e.target.value)}
//           />
//         </label>
//       </div>
//       <button onClick={handleTrack}>Track</button>

//       {error && <p style={{ color: 'red' }}>{error}</p>}
//       {trackingInfo && (
//         <div>
//           <h3>Tracking Information:</h3>
//           <pre>{JSON.stringify(trackingInfo, null, 2)}</pre>
//         </div>
//       )}
//     </div>
//    </div>
//   );
// };

// export default TrackParcel;


// src/TrackParcel.js

// import React, { useState } from 'react';
// import { getOrderShipmentDetails } from './Api';
// import Nav from '../Nav';

// const TrackParcel = () => {
//   const [orderId, setOrderId] = useState('');
//   const [trackingInfo, setTrackingInfo] = useState(null);
//   const [error, setError] = useState(null);

//   const handleTrack = async () => {
//     try {
//       const data = await getOrderShipmentDetails(orderId);
//       if (data.status === 'Success') {
//         setTrackingInfo(data.response);
//         setError(null);
//       } else {
//         setTrackingInfo(null);
//         setError('No tracking information available.');
//       }
//     } catch (err) {
//       setTrackingInfo(null);
//       setError('Failed to track parcel. Please check the order ID and try again.');
//     }
//   };

//   return (
//     <div>
//       <Nav />
//       <div className="container mx-auto my-32 p-4">
//         <h2 className="text-2xl font-bold text-center mb-6">Track Your Parcel</h2>
//         <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
//           <div className="mb-4">
//             <label className="block text-gray-700 text-sm font-bold mb-2">
//               Order ID:
//             </label>
//             <input
//               type="text"
//               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//               value={orderId}
//               onChange={(e) => setOrderId(e.target.value)}
//             />
//           </div>
//           <button
//             className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//             onClick={handleTrack}
//           >
//             Track
//           </button>

//           {error && (
//             <p className="text-red-500 text-xs italic mt-4">
//               {error}
//             </p>
//           )}
//           {trackingInfo && (
//             <div className="mt-6">
//               <h3 className="text-xl font-semibold">Tracking Information:</h3>
//               <div className="bg-gray-100 p-4 rounded-lg mt-4">
//                 <p><strong>Status:</strong> {trackingInfo.current_status}</p>
//                 <p><strong>Carrier:</strong> {trackingInfo.carrier}</p>
//                 <p><strong>Customer Name:</strong> {trackingInfo.customer_name}</p>
//                 <p><strong>AWB No:</strong> {trackingInfo.awbno}</p>
//                 {trackingInfo.tracking_url && (
//                   <p>
//                     <strong>Tracking URL:</strong> <a href={trackingInfo.tracking_url} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">{trackingInfo.tracking_url}</a>
//                   </p>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TrackParcel;


import React, { useState } from 'react';
import { getOrderShipmentDetails } from './Api';
import Nav from '../Nav';

const TrackParcel = () => {
    const [orderId, setOrderId] = useState('');
    const [trackingInfo, setTrackingInfo] = useState(null);
    const [error, setError] = useState(null);

    const handleTrack = async () => {
        try {
            const data = await getOrderShipmentDetails(orderId);
            if (data.status === 'Success') {
                setTrackingInfo(data.response);
                setError(null);
            } else {
                setTrackingInfo(null);
                setError('No tracking information available.');
            }
        } catch (err) {
            setTrackingInfo(null);
            setError('Failed to track parcel. Please check the order ID and try again.');
        }
    };

    return (
        <div>
            <Nav />
            <div className="container mx-auto my-32 p-4">
                <h2 className="text-2xl font-bold text-center mb-6">Track Your Parcel</h2>
                <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Order ID:
                        </label>
                        <input
                            type="text"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                        />
                    </div>
                    <button
                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleTrack}
                    >
                        Track
                    </button>


                    {error && (
                        <p className="text-red-500 text-xs italic mt-4">
                            {error}
                        </p>
                    )}
                    <div className="max-w-md mx-auto bg-gray-50 my-40 rounded-xl shadow-md space-y-4">
                        <div className="text-center">
                            <h2 className="text-xl font-semibold">ORDER TRACKING</h2>
                            <p className="text-sm text-gray-500">
                                Please note that these are accurate but not guaranteed estimates. Delivery date is subject to change without advanced notice.
                            </p>
                        </div>


                        <div className="border bg-white rounded-lg p-4 space-y-2">
                            <div className="flex justify-between">
                                <span>ORDER PLACED</span>
                                <span>TOTAL</span>
                                <span>SHIP TO</span>
                                <span>ORDER</span>
                            </div>
                            <div className="flex justify-between">
                                <span>{trackingInfo?.pickup_date}</span>
                                <span>{trackingInfo?.order_data}</span>
                                <span>{trackingInfo?.recipient}</span>
                                <span>{trackingInfo?.order_id}</span>
                            </div>
                        </div>

                        <div className="space-y-2">
                            {trackingInfo?.scan.map((scan, index) => (
                                <div key={index} className="flex items-center justify-between">
                                    <div className="flex-1 flex flex-col items-center">
                                        <div
                                            className={`bg-${index === 0 ? 'green-600' : 'gray-300'} text-white rounded-full h-8 w-8 flex items-center justify-center`}>
                                            {index === 0 ? '✓' : ''}
                                        </div>
                                        <span className="text-xs mt-2">{scan.status_detail}</span>
                                        <span
                                            className={`text-xs text-gray-500 ${index === 0 ? '' : 'text-gray-500'}`}>
                                            {scan.time}
                                        </span>
                                    </div>
                                    {index !== trackingInfo.scan.length - 1 && (
                                        <div className="flex-1 flex justify-center">
                                            <div className="h-px w-full bg-gray-300"></div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        {trackingInfo?.extra_fields?.expected_delivery_date && (
                            <div className="text-center text-sm text-gray-500">
                                Estimated Delivery Date: {trackingInfo?.extra_fields?.expected_delivery_date}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackParcel;