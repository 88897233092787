
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Nav from '../Nav';
// import { useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTruck, faBoxOpen } from "@fortawesome/free-solid-svg-icons";


const TrackingWhtasapp = () => {
  const { order_id } = useParams();
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const [onPods , setOnPods] = useState(true)

  const statusIcons = {
    "DELIVERED": faCheckCircle,
    "IN_TRANSIT": faTruck,
    "OUT_FOR_DELIVERY": faTruck,
    "ORDER_PLACED": faBoxOpen
};

const statusColors = {
    "DELIVERED": "text-green-500",
    "IN_TRANSIT": "text-blue-500",
    "OUT_FOR_DELIVERY": "text-yellow-500",
    "ORDER_PLACED": "text-gray-500"
};

const handleClick = () => {
setOnPods(false)
}

  useEffect(() => {
    const fetchTrackingInfo = async () => {
      try {
        const response = await fetch(`https://pickupkart.in/api/track?order_id=${order_id}`);
        const data = await response.json();
        setTrackingInfo(data.response);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTrackingInfo();
  }, [order_id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className='mt-20'>
     
      <div>
        <Nav />
        {/* <pre>{JSON.stringify(trackingInfo, null, 2)}</pre> */}
        <div className="p-6 max-w-7xl mx-auto md:bg-gray-50 my-20 lg:my-40 rounded-xl shadow-md ">
          <div className="">
            <div className='flex item-center justify-center'>
              <h1 className="lg:text-3xl text-2xl text-gray-700 text-center   font-bold ">STATUS -  </h1>
              <h2 className={`lg:text-3xl text-2xl ml-4 text-center  font-semibold ${trackingInfo.current_status === "Delivered" ? "text-green-500" : "text-blue-500"}`}>{trackingInfo.current_status == "Delivered" ? trackingInfo.current_status : "In-transist"}</h2>
             {/* <button onClick={handleClick}>on pod</button>
             {onPods ? "" : <img src={trackingInfo.extra_fields.pod}></img>} */}
            </div>
            <p className="text-lg lg:block hidden text-gray-500 my-8 text-center">
              Please note that these are accurate but not guaranteed estimates. Delivery date is subject to change without advanced notice.
            </p>
          </div>


          <div class="bg-white rounded-lg p-4 space-y-2 border-collapse border-none hidden md:block">
            <table class="table-auto w-full border-none border-collapse">
              <thead className='border-none border-collapse'>
                <tr>
                  {trackingInfo.recipient === "" ? "" : <th class="uppercase text-gray-500 border-none">Recipient</th>}
                  <th class="uppercase text-gray-500 border-none uppercase">carrier</th>
                  <th class="uppercase text-gray-500 border-none uppercase">awb no</th>

                  {trackingInfo.from == null || trackingInfo.to == null ? "" : <th class="uppercase text-gray-500 border-none">FROM</th>}
                  {trackingInfo.from == null || trackingInfo.to == null ? "" : <th class="uppercase text-gray-500 border-none">SHIP TO</th>}

                  <th class="uppercase text-gray-500 border-none">ORDER ID</th>
                </tr>
              </thead>
              <tbody className='border-none'>
                <tr>
                  {trackingInfo.recipient === "" ? "" : <td class="border-none"> {trackingInfo.recipient} </td>}
                  <td class="border-none"> {trackingInfo.carrier} </td>
                  <td class="border-none"> {trackingInfo.awbno} </td>
                  {trackingInfo.from == null || trackingInfo.to == null ? " " : <td class="border-none"> {trackingInfo.from} </td>}
                  {trackingInfo.from == null || trackingInfo.to == null ? " " : <td class="border-none"> {trackingInfo.to} </td>}
                  <td class="border-none"> #{order_id} </td>
                </tr>
              </tbody>
            </table>
          </div>

     


          <div className=" mx-auto p-4 md:hidden">
            <table className="min-w-full  border border-gray-200">
              <tbody>

                {trackingInfo.recipient === "" ? "" :
                  <tr className="border-b ">
                    <th className="text-left px-3 py-2 border-r">Recipient</th>
                    <td className="text-left bg-yellow-50 px-3 py-2">{trackingInfo.recipient}</td>
                  </tr>}


                <tr className="border-b">
                  <th className="text-left px-3 py-2 bg-green-50 border-r">Carrier</th>
                  <td className="text-left px-3 bg-yellow-50 py-2">{trackingInfo.carrier}</td>
                </tr>
                <tr className="border-b">
                  <th className="text-left px-3 bg-green-50  py-2 border-r">awb no</th>
                  <td className="text-left px-3 bg-yellow-50 py-2">{trackingInfo.awbno}</td>
                </tr>
                <tr className="border-b">
                  <th className="text-left px-3 bg-green-50 py-2 border-r">Order id</th>
                  <td className="text-left px-3 bg-yellow-50 py-2">{order_id}</td>
                </tr>

                {trackingInfo.from == null || trackingInfo.to == null ? " " : <tr className="border-b">
                  <th className="text-left px-3 py-2 bg-green-50  border-r">From</th>
                  <td className="text-left px-3 bg-yellow-50 py-2">{trackingInfo.from}</td>
                </tr>}
                {trackingInfo.from == null || trackingInfo.to == null ? " " : <tr className="border-b">
                  <th className="text-left px-3 bg-green-50  py-2 border-r">To</th>
                  <td className="text-left px-3 bg-yellow-50  py-2">{trackingInfo.to}</td>
                </tr>}

              </tbody>
            </table>
          </div>

          <div className="   lg:p-6 rounded-lg   ">

            <div  >
              <h2 className="md:text-xl mt-10  font-semibold ">Tracking Details</h2>
              <div className="lg:grid lg:grid-cols-4">
                {trackingInfo.scan.map((event, index) => (

                  <div key={index} className=" flex md:items-center lg:px-4 lg:py-4 py-4">
                    <div>
                      <p className='text-sm md:hidden font-medium text-blue-600 mb-2'> {event.time.substring(11, 19)}</p> 
                      <FontAwesomeIcon icon={statusIcons[event.status_detail.toUpperCase()] || faTruck} className={`mr-6 flex text-2xl ${statusColors[event.status_detail.toUpperCase()] || "text-gray-500"}`} />
                    </div>

                    <div className=' lg:pl-1 pl-10'>
                      <p className='text-sm md:hidden font-medium text-blue-600'> {event.time.substring(0, 10)}</p> 

                      <p className='text-lg hidden md:block text-blue-600'> {event.time}</p>
                      <p className='md:text-lg lowercase'>{event.status_detail}</p>
                      <p className='md:text-lg lowercase'>{event.location}</p>
                    </div>

                  </div>
                ))}
              </div>
            </div>
          </div>
         
        </div>


      </div>
    </div>
  );
};

export default TrackingWhtasapp;
