import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Nav from '../Nav';

const ThankYouPage = () => {
    const location = useLocation();
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        if (location.state.orderId) {
            setOrderId(location.state.orderId);
        }
    }, [location.state.orderId]);

    return (
        <div>
            <Nav />
            <div className='mt-40 mb-20 itmem-center justify-center mx-auto text-center'>
                <h1 className='text-2xl mx-2'> Thanks for booking!</h1>
                <div className='bg-green-400 text-2xl lg:hidden mx-auto my-6  px-4 py-4 rounded-full w-20 text-white'>✔</div>

                <div className='flex itmem-center justify-center gap-4 ' >
                    <div className='bg-green-400 text-2xl hidden lg:block px-4 py-4 rounded-full text-white'>✔</div>
                    <p className='my-auto md:flex mx-6 lg:mx-0 text-2xl'>Your shipment has been successfully booked with <p >tracking ID: </p>  <p className='text-3xl ml-2 font-bold text-green-600'>{orderId}</p></p>
                </div>
                <p className='text-yellow-600 pt-4'> your pickup has sheduled

                    if any quary please <Link to = "/Contact" className='text-blue-400 underline'>contact as </Link> </p>

            </div>
        </div>
    );
};

export default ThankYouPage