import React from "react";
import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import wtsp from '../Images/wtsp.png'
import bottompersonbg from "../Images/bgbottom.png";
import delivarytruck from "../Images/delivary-truck.png";
import homedelivery1 from "../Images/home delivery 1.png";
import homedelivery2 from "../Images/cahart1.png";
import bannerpng from "../Images/banner2png.png"
import home from "../Images/home.png";
import personimgbottom from "../Images/person-img-bottom.png";
import rightannimation from "../Images/right-annimation.png";
import rod1 from "../Images/rod-1.png";
import rod2 from "../Images/rod-2.png";
import rod3 from "../Images/rod-3.png";
import rod5 from "../Images/rod-5.png";
import rod6 from "../Images/rod-6.png";
import rod7 from "../Images/rod-7.png";
import rod8 from "../Images/rod-8.png";
import rod9 from "../Images/rod-9.png";
import rod10 from "../Images/rod-10.png";
import rod11 from "../Images/rod-11.png";
import rod12 from "../Images/rod-12.png";
import rod4 from "../Images/rod4.png";
import rod13 from "../Images/rod13.png";
import rod14 from "../Images/rod14.png";
import rod15 from "../Images/rod15.png";
import './Main.css';
import '../processbar.css';

import Nav from "../Nav";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Loader from "../Loader/Loader";
import BlurredBackground from "../Loader/BlurredBack";
import PickupMode from "./component/pickupMode";
import Trackship from "./component/shipmenttrack/Trackship";


const Main = () => {



    AOS.init({
        duration: 600,
        delay: 390,
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const handleBeforeUnload = () => {
            sessionStorage.setItem('hasEntered', 'true');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        const hasEntered = sessionStorage.getItem('hasEntered');

        if (hasEntered) {
            setIsLoading(false);
        } else {
            const timer = setTimeout(() => {
                setIsLoading(false);
                sessionStorage.setItem('hasEntered', 'true');
            }, 1900);
            return () => clearTimeout(timer);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);




    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
        setHasMounted(true);
    }, []);
    if (!hasMounted) {
        return null;
    }


    return (<>

        {isLoading ? (
            <BlurredBackground>
                <Loader />
            </BlurredBackground>
        ) : (<>
            <Nav />
            <a href="https://wa.me/+917350926425" target="_blank" rel="noopener noreferrer"><div className="fixed  bottom-[1.5vw] right-[1.5vw]  z-[100000] "><img src={wtsp} width={"50px"} /></div></a>

            <section className="w-full mt-20 h-full overflow-x-hidden overflow-y-hidden" >



                <div className="bg-gradient-to-l  from-purple-200    w-full">
                    <div className=" grid lg:grid-cols-2  ">
                        <div className="px-8  py-10">
                            <div class="lg:ml-8  mb-4 lg:hidden  ">
                                <Trackship />
                            </div>
                            <h1 className="lg:text-[65px] text-[40px] leading-normal font-medium ">Calculate <p className=" font-bold bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">Shipping rate Instantly</p></h1>
                            <p className="py-4 font-medium">Book Courier Conveniently from your home or office.
                                Estimate courier charges using the PickupKart
                                price calculator.

                            </p>

                            <div className="lg:flex">
                                <a href="tel:7350926425" class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 lg:px-4 px-2 border border-blue-500 hover:border-transparent rounded-full">
                                    Call Now on 7350926425
                                </a>
                                <div class="lg:ml-8  hidden lg:block   ">
                                    <Trackship />
                                </div>

                            </div>
                        </div>
                        <div>
                            <img src={bannerpng} className=" mr-20   max-[1024px]:hidden" alt="" />

                        </div>
                        {/* <div className="px-8 sm:hidden mb-10">
                            <Link to="/BookNow"> <a class="box-border relative  inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-indigo-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                                <span class="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                                <span class="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                                <span class="relative  flex items-center text-sm">
                                    <svg class="relative w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
                                    Quick Book
                                </span>
                            </a></Link>
                        </div> */}
                    </div>
                </div>
                <div className="bg-sky-50 pb-10 pt-6 lg:pt-10">
                    <h1 className="lg:text-[50px] text-[20px] lg:pl-80 pl-20   mb-8 font-bold bg-gradient-to-r from-blue-600 via-green-500  to-blue-400 inline-block text-transparent bg-clip-text">Shipping rates calculator </h1>
                    <PickupMode />
                </div>



                <section className="w-full h-full   max-[900px]:pt-8 mt-10 max-[768px]:mt-[10px] flex flex-col mx-auto justify-center items-center relative">
                    <img src={rod1} className="absolute left-0 top-[26%] -z-50 max-[1500px]:hidden" alt="" />
                    <img src={delivarytruck} className="absolute left-[35%] top-[26%]  max-[1500px]:hidden" alt="" />
                    <img src={rod2} className="absolute left-[49.7%] top-[26%]  max-[1500px]:hidden" alt="" />
                    <img src={rod3} className="absolute left-[17%] top-[33.3%] -z-60 max-[1500px]:hidden" alt="" />
                    <img src={rod4} className="absolute left-[18%] top-[33%] -z-50 max-[1500px]:hidden" alt="" />
                    <img src={rightannimation} className="absolute right-0 top-[45%]  max-[1500px]:hidden" alt="" />

                    <img src={rod5} className="absolute bottom-[34%] left-[17%] max-[1500px]:hidden" alt="" />
                    <img src={rod6} className="absolute bottom-[27.3%] right-[31.8%] max-[1500px]:hidden" alt="" />
                    <img src={rod7} className="absolute bottom-[27.3%] right-[31.8%] max-[1500px]:hidden" alt="" />
                    <img src={rod8} className="absolute top-[70%] left-[35%] max-[1500px]:hidden" alt="" />



                    <div data-aos="fade-up" className="flex flex-col items-center gap-3 mx-auto">
                        <p className="text-[40px] font-ROBOTO font-semibold max-[640px]:text-[27px]">A Problem Solving Solution For Doorstep Pickup Courier Service</p>
                        <p className="text-[28px] font-ROBOTO font-medium opacity-60 max-[640px]:text-[16px]"> Using Our Feature-rich platform </p>
                    </div>


                    <div data-aos="fade-up" className="mx-auto w-full flex flex-row items-start justify-between xl:gap-25 max-[1280px]:gap-20 gap-48 max-[1024px]:w-full max-[1024px]:pl-[3%] pl-[7%] pt-16">
                        <img src={homedelivery1} className="z-50 xl:w-[40%]  max-[1280px]:w-[35%] max-[1024px]:hidden" alt="" />
                        <div className="max-[1280px]:pl-24 max-[768px]:pl-0">
                            <p className="text-[28px] font-ROBOTO font-extrabold max-[640px]:text-[24px]">FOLLOW STEPS</p>
                            <div className="flex flex-row gap-6 pt-4 ">
                                <div className="w-[50px] h-[50px] rounded-full bg-[#EF8038] flex items-center pl-1">
                                    <img src={home} className="pl-[0.62rem]" alt="" />
                                </div>
                                <div className="w-[50%] max-[1280px]:w-[70%] max-[1024px]:w-[80%] flex flex-col gap-3 max-[1024px]:gap-0">
                                    <p data-aos="fade-up" className="text-[21px] font-ROBOTO font-bold -mb-4 max-[640px]:text-[18px]">Visit Our Website</p>
                                    <div className="flex flex-row items-center gap-3">
                                        <p data-aos="fade-up" className="text-[15px] text-[grey] font-ROBOTO font-medium  max-[640px]:text-[15px]">Visit our website and calculate your rate for shipment and create a pickup.</p>
                                        <span className="font-ROBOTO text-[70px] font-bold text-[#E0E0E0]">01</span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-6 pt-4">
                                <div className="w-[50px] h-[50px] rounded-full bg-[#EF8038] flex justify-center items-center">
                                    <i className="fa-solid fa-truck-pickup text-white"></i>
                                </div>
                                <div className="w-[50%] max-[1280px]:w-[70%] max-[1024px]:w-[80%] flex flex-col gap-3 max-[1024px]:gap-0">
                                    <p data-aos="fade-up" className="text-[21px] font-ROBOTO font-bold -mb-4 max-[640px]:text-[18px]">Doorstep pickup</p>
                                    <div className="flex flex-row items-center gap-3">
                                        <p data-aos="fade-up" className="text-[15px] font-ROBOTO font-medium text-[grey] max-[640px]:text-[15px]">Our executive will reach at your doorstep and collect your
                                            parcel or document.</p>
                                        <span className="font-ROBOTO text-[70px] font-bold text-[#E0E0E0]">02</span>
                                    </div>
                                </div>
                            </div>


                            <div className="flex flex-row gap-6 pt-4">
                                <div className="w-[50px] h-[50px] rounded-full justify-center bg-[#EF8038] flex items-center">
                                    <i className="fas fa-receipt  text-white"></i>
                                </div>
                                <div className="w-[50%] max-[1280px]:w-[70%] max-[1024px]:w-[80%] flex flex-col gap-3 max-[1024px]:gap-0">
                                    <p data-aos="fade-up" className="text-[21px] font-ROBOTO font-bold -mb-4 max-[640px]:text-[18px]">Pay & Tracking</p>
                                    <div className="flex flex-row items-center gap-3">
                                        <p data-aos="fade-up" className="text-[15px] font-ROBOTO font-medium text-[grey] max-[640px]:text-[15px]">Pay courier charges by cash or upi and get instant tracking number.</p>
                                        <span className="font-ROBOTO text-[70px] font-bold text-[#E0E0E0]">03</span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-6 pt-4">
                                <div className="w-[50px] h-[50px] rounded-full  bg-[#EF8038] flex items-center justify-center">
                                    <i className="fas fa-location text-white"></i>                                </div>
                                <div className="w-[50%] max-[1280px]:w-[70%] max-[1024px]:w-[80%] flex flex-col gap-3 max-[1024px]:gap-0">
                                    <p data-aos="fade-up" className="text-[21px] font-ROBOTO font-bold -mb-4 max-[640px]:text-[18px]">Live Tracking</p>
                                    <div className="flex flex-row items-center gap-3">
                                        <p data-aos="fade-up" className="text-[15px] w-[100%] font-ROBOTO font-medium text-[grey] max-[640px]:text-[15px]">Live track your courier AWB number.</p>
                                        <span className="font-ROBOTO text-[70px] font-bold text-[#E0E0E0]">04</span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-6 pt-4">
                                <div className="w-[50px] h-[50px] rounded-full  bg-[#EF8038] flex items-center justify-center">
                                    <i className="fas fa-shipping-fast text-white"></i>                                </div>
                                <div className="w-[50%] max-[1280px]:w-[70%] max-[1024px]:w-[80%] flex flex-col gap-3 max-[1024px]:gap-0">
                                    <p data-aos="fade-up" className="text-[21px] font-ROBOTO font-bold -mb-4 max-[640px]:text-[18px]">Doorstep Delivery</p>
                                    <div className="flex flex-row items-center gap-3">
                                        <p data-aos="fade-up" className="text-[15px] w-[100%] font-ROBOTO font-medium text-[grey] max-[640px]:text-[15px]">Parcel delivered on your destination
                                            with safe and secure.</p>
                                        <span className="font-ROBOTO text-[70px] font-bold text-[#E0E0E0]">05</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                    <div data-aos="fade-up" className="flex flex-row pl-[11%] max-[768px]:pl-0  mt-32  xl:gap-25 gap-48 max-[1024px]:w-full max-[768px]:w-[85%]  max-[1280px]:gap-10 max-[1024px]:pl-[10%] justify-between items-start mx-auto w-full relative">

                        <div>
                            <div data-aos="fade-up" className="w-[100%] shadow-md rounded-lg bg-[#F9F9F9] pl-10 pt-14 pb-12  relative max-[768px]:p-9 max-[768px]:w-[100%]">
                                <p data-aos="fade-up" className="text-[28px] font-ROBOTO pb-2 font-extrabold max-[640px]:text-[24px]">WHY CHOOSE US</p>
                                <p data-aos="fade-up" className="text-[15px] font-ROBOTO font-medium text-[grey] max-[640px]:text-[16px]"></p>
                                <div data-aos="fade-up" className="flex flex-row gap-6 pt-6">
                                    <div className="w-[50px] h-[50px] rounded-full  bg-[#EF8038] flex items-center justify-center">
                                        <i className="fas fa-shipping-fast text-white"></i>                                </div>
                                    <div className="w-[78%] flex flex-col gap-3]">
                                        <p data-aos="fade-up" className="text-[24px] font-ROBOTO font-bold max-[640px]:text-[20px] ">From Your Door To Every Door Courier Pickup And Delivery Service</p>

                                        <p data-aos="fade-up" className="text-[18px] font-ROBOTO font-medium text-[grey] max-[640px]:text-[16px]">We are exepert in doorstep courier pickup and delivery all over india with more than 18000+ pincodes servicable</p>
                                        <p data-aos="fade-up" className="text-[18px] mt-4 font-ROBOTO font-medium text-[grey] max-[640px]:text-[16px]">From Your Door To Every  door we work with huge varity of indrusties sectore , form fashion to engineering , pharmacaticals , automotive , ecommerce to household goods etc.</p>

                                    </div>
                                </div>

                                <div data-aos="fade-up" className="flex flex-row gap-6 pt-4 pb-24">
                                    <div className="w-[50px] h-[50px] rounded-full  bg-[#EF8038] flex items-center justify-center">
                                        <i className="fa-solid fa-helmet-safety text-white"></i>                               </div>
                                    <div className="w-[80%] flex flex-col pt-2 gap-3">
                                        <p data-aos="fade-up" className="text-[21px] font-ROBOTO font-bold max-[640px]:text-[20px]">Safety and Reliability</p>

                                        <p data-aos="fade-up" className="text-[15px] font-ROBOTO font-medium text-[grey] max-[640px]:text-[13px]">Our
                                            delivary system has proven to safety delivery and maximum security coverage for lost shipment. </p>
                                    </div>
                                </div>

                                <button
                                    className="w-[155px] h-[44px] bg-[#EF8038] text-white text-[16px] rounded-md absolute bottom-10 right-8">Get - Set - Ship</button>
                            </div>
                        </div>

                        <img src={homedelivery2} className="xl:w-[40%] mr-20  max-[1280px]:w-[35%] max-[1024px]:hidden" alt="" />
                    </div>
                </section>


                {/* <!-- Feature Section --> */}
                <section className="relative pt-40 pb-40 max-[768px]:pt-16">

                    <img src={rod9} className="absolute top-[15%] left-[35%] max-[1500px]:hidden" alt="" />
                    <img src={rod10} className="absolute top-[15%] right-[13%] -z-[50] max-[1495px]:hidden" alt="" />
                    <img src={rod11} className="absolute top-[23%] right-[13%] -z-[50] max-[1495px]:hidden" alt="" />
                    <img src={rod12} className="absolute top-[22.8%] right-[45%] -z-[50] max-[1495px]:hidden" alt="" />
                    <img src={rod13} className="absolute top-[63%] left-[35%] max-[1495px]:hidden" alt="" />
                    <img src={rod14} className="absolute bottom-[5%] left-[35%] max-[1495px]:hidden" alt="" />
                    <img src={rod15} className="absolute bottom-[-3%] right-[14%] -z-[50] max-[1495px]:hidden" alt="" />

                </section>


                {/* <!-- Footer Section --> */}
                <section>
                    {/* <!-- upper part --> */}
                    <div className="flex bg-[#FFF4F0] flex-row  max-[768px]:flex-col">
                        <div className="relative">
                            <img src={personimgbottom} className="absolute left-16 bottom-[-0.05rem] max-[1024px]:left-6 max-[640px]:h-[120%]" alt="" />
                            <img src={bottompersonbg} className="w-full h-full" alt="" />
                        </div>
                        <div className="flex  flex-col gap-7 p-5 lg:pt-14 max-[768px]:pl-2">
                            <p className="text-[#0260AA] font-ROBOTO text-[28px] font-semibold tracking-wide max-[768px]:text-[24px]"> ON - CALL PICKUP SERVICE</p>

                            <p className="text-[gray] font-ROBOTO font-medium tracking-wide opacity-80 text-[18px]  max-[768px]:text-[13px]">This service specially designed for individuals or businesses
                                <br /> - comfortably place your order</p>
                            <div className="flex flex-row gap-3 text-[#EF8038]">
                                <p className=" text-[28px] font-[dosis] font-ROBOTO  max-[768px]:text-[22px]">Call Us:</p>
                                <a href="tel:7350926425"> <p className=" text-[28px] hover:text-blue-500 font-[dosis] max-[768px]:text-[22px]">+91 7350926425</p></a>
                            </div>
                        </div>
                    </div>




                </section>


            </section></>)}

    </>);
}

export default Main;

