
import React, { useEffect, useRef, useState } from 'react';

const PrivacyPolicy = () => {
    const sections = [
        "Information We Collect",
        "Purpose of Data Collection",
        "How We Collect Data",
        "User Consent",
        "Data Security",
        "Data Sharing",
        "User Rights",
        "Changes to This Privacy Policy",
        "Contact Us",
        "Conclusion"
    ];

    const sectionRefs = useRef(sections.map(() => React.createRef()));

    const [activeSection, setActiveSection] = useState("");

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.6 }
        );

        sectionRefs.current.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, []); 

    return (
        <div className="bg-white mt-18">
            <div className="mx-auto">
                <div className='bg-purple-800 text-white px-40 text-center rounded-t py-12'>
                    <h1 className="text-3xl font-bold">Privacy Policy</h1>
                    <p className="mt-2">
                        Welcome to 5TG-SBM, an HRM CRM application designed to help employers manage their workforce effectively. At 5TG-SBM, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information.
                    </p>
                </div>
                <div className="bg-white text-black mt-10 p-6 rounded-lg shadow-md mx-40">
                    <div className="flex">
                        <div className="w-1/4 pr-4 hidden md:block">
                            <ul className="text-md font-semibold">
                                {sections.map((section, index) => (
                                    <li
                                        key={section}
                                        className={`mt-2 ${activeSection === section ? "text-blue-600" : ""}`}
                                        onClick={() => {
                                            const element = document.getElementById(section);
                                            if (element) {
                                                element.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        }}
                                    >
                                        {section}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-3/4 pl-4 overflow-scroll h-screen">
                            {sections.map((section, index) => (
                                <div key={section} id={section} ref={sectionRefs.current[index]} className="mt-4">
                                    <h2 className="text-2xl font-bold">{section}</h2>
                                    <p className="mt-2">
                                        {section === "Information We Collect" && (
                                            <>
                                                <strong>Location Information</strong><br />
                                                Precise Location: We collect precise location data to track the real-time location of employees on the field. <br />
                                                Approximate Location: We collect approximate location data for situations where precise location is not required. <br />
                                                Background Location: We collect location data in the background to ensure continuous tracking even when the app is not actively in use.
                                                <br /><br />
                                                <strong>Photos</strong><br />
                                                We collect and store photos that users capture using the app. These photos are stored in the private space of the app and are not accessible from external directories.
                                            </>
                                        )}

                                        {section === "Purpose of Data Collection" && (
                                            <>
                                                The data we collect is essential for the following purposes: <br />
                                                <strong>Employee Tracking:</strong> Employers use location data to track their employees' whereabouts on the field. This helps in managing field operations efficiently. <br />
                                                <strong>Internal Use Only:</strong> All collected data is processed on our secure servers and displayed only to the employer's admin. We do not process or share this data with any third parties.
                                            </>
                                        )}
                                        {section === "How We Collect Data" && (
                                            <>
                                                We collect data through the permissions granted by the users: <br />
                                                <strong>Precise Location Permission:</strong> To collect accurate location data. <br />
                                                <strong>Approximate Location Permission:</strong> To gather general location data when precise tracking is unnecessary. <br />
                                                <strong>Background Location Permission:</strong> To continuously track location data even when the app is not in active use.
                                            </>
                                        )}
                                        {section === "User Consent" && (
                                            <>
                                                Before collecting any data, we request user permission and provide clear information about why these permissions are necessary. Users are educated about the importance of each permission through in-app prompts and documentation.
                                            </>
                                        )}
                                        {section === "Data Security" && (
                                            <>
                                                We are committed to protecting your personal information. Data is stored securely on our servers and is only accessible to authorized personnel. Photos and location data are stored in the private space of the app and are not accessible from external directories.
                                            </>
                                        )}
                                        {section === "Data Sharing" && (
                                            <>
                                                We do not share your personal data with third parties. All data collected is used exclusively for the purposes stated in this Privacy Policy and is accessible only to the employer's admin within the organization.
                                            </>
                                        )}
                                        {section === "User Rights" && (
                                            <>
                                                Users have the right to: <br />
                                                <strong>Access:</strong> Request access to the data we have collected. <br />
                                                <strong>Correction:</strong> Request corrections to any inaccurate or incomplete data. <br />
                                                <strong>Deletion:</strong> Request the deletion of their data, subject to company policies and legal requirements.
                                            </>
                                        )}
                                        {section === "Changes to This Privacy Policy" && (
                                            <>
                                                We may update this Privacy Policy from time to time. Any changes will be communicated to users through in-app notifications and will be effective immediately upon posting.
                                            </>
                                        )}
                                        {section === "Contact Us" && (
                                            <>
                                                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at Contact@5techg.com.
                                            </>
                                        )}
                                        {section === "Conclusion" && (
                                            <>
                                                We value your privacy and strive to protect your personal information. By using 5TG-SBM, you consent to the collection and use of your data as described in this Privacy Policy.
                                            </>
                                        )}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
